






























































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { User } from '../../types/user'
import gql from 'graphql-tag'

@Component
export default class extends Vue {
  @Prop() private user!: User
  private onFetch = false

  private async changeInfo() {
    this.onFetch = true
    await this.$apollo.mutate({
      mutation: gql`
        mutation ChangeUserInfo($data: EditUserInput!) {
          editUser(data: $data) {
            id
          }
        }
      `,
      variables: {
        data: this.user,
      },
    })
    this.onFetch = false
  }
}
