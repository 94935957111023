






































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { vxm } from '@/store'
import { User } from '../types/user'
import gql from 'graphql-tag'
import StudentTabs from '@/components/Student/StudentTabs.vue'
import TimtableComponent from '@/components/Student/Timetable.vue'

@Component({
  components: {
    StudentTabs,
    TimtableComponent,
  },
  computed: {
    quarterLoad: () => vxm.quarter.isLoading,
    quarters: () =>
      vxm.quarter.allQuarters.map(el => ({ text: el.name, value: el.id })),
    quarter: {
      get() {
        return vxm.quarter.activeQuarter.id
      },
      set(e: number) {
        vxm.quarter.setActiveQuarterById(e)
      },
    },
  },
})
export default class extends Vue {
  private quarter!: number
  private onFetch = false
  private user: null | Partial<User> = null
  private registrationStates = [
    { text: 'Unregister', value: 'unregister' },
    { text: 'Registered CR', value: 'registeredCourse' },
    { text: 'Transfered CR', value: 'transferedCourse' },
    { text: 'Registered HB', value: 'registeredHybrid' },
    { text: 'Approved', value: 'approved' },
    { text: 'Finished', value: 'finished' },
  ]
  private dropStates = [
    { text: 'None', value: 'none' },
    { text: 'Dropped', value: 'dropped' },
  ]

  @Watch('quarter', { immediate: true })
  onQuarterChange(val: number) {
    if (val) {
      this.fetchUser()
    }
  }

  mounted() {
    vxm.quarter.fetchQuarters()
  }

  private async fetchUser() {
    this.onFetch = true
    const { data } = await this.$apollo.query<{ user: Partial<User> }>({
      query: gql`
        query FetchUserInfo($data: String, $quarterId: Int!) {
          user(username: $data) {
            id
            username
            firstName
            firstNameEn
            lastName
            lastNameEn
            nickname
            nicknameEn
            email
            phone
            profilePicture {
              signedUrl
            }
            walletSummaries {
              subject
              wallets {
                balance
              }
            }
            eligibleSections {
              classId
              day
              duration
              startTime
              tutor {
                nickname
              }
            }
            registeredSections {
              classId
              day
              duration
              startTime
              tutor {
                nickname
              }
            }
            quarterProfile(quarterId: $quarterId) {
              id
              grade
              registrationState
              dropState
              remark
              quarterFiles {
                url
                deleted
                type
              }
            }
          }
        }
      `,
      variables: {
        data: this.$route.params.userId,
        quarterId: this.quarter,
      },
      fetchPolicy: 'no-cache',
    })
    if (data) {
      this.user = data.user
    }
    this.onFetch = false
  }

  private async changeState() {
    this.onFetch = true
    await this.$apollo.mutate({
      mutation: gql`
        mutation ChangeQuarterState($data: EditQuarterProfileArgs!) {
          updateQuarterProfile(data: $data) {
            id
          }
        }
      `,
      variables: {
        data: this.user?.quarterProfile,
      },
    })
    this.onFetch = false
  }
}
