




















































import { Vue, Component } from 'vue-property-decorator'

@Component
export default class extends Vue {
  private dow = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
  private times = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
  private selectedDay = ''
  private selectedTime = ''
  private addClassDialog = false

  private closeAddClassDialog() {
    this.addClassDialog = false
    this.selectedDay = ''
    this.selectedTime = ''
  }

  private async addStudentClass() {
    // TODO: Add this function later
  }
}
