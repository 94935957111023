















































import { Vue, Component, Prop } from 'vue-property-decorator'
import StudentInfo from '@/components/Student/StudentInfo.vue'
import { User } from '@/types/user'

@Component({
  components: {
    StudentInfo,
  },
})
export default class extends Vue {
  @Prop() private user?: User
  private tabs = ['Info', 'CR Receipt', 'SK Receipt']
  private activeTab = null

  private receiptUrl(type: string) {
    if (
      this.user &&
      this.user.quarterProfile &&
      this.user.quarterProfile.quarterFiles
    ) {
      const receipt = this.user.quarterProfile.quarterFiles.find(
        el => el.type === `${type}Reciept`
      )
      if (receipt) {
        return receipt.url
      }
    }
    return ''
  }
}
